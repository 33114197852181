import { React, Fragment, useState, useEffect } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Headline(props) {
  return (
    <>
      <div
        key={"key"}
        className={classNames(
          1 === 1 ? "sm:border-l" : 1 === 2 ? "lg:border-l" : "",
          "flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8"
        )}
      >
        <dt className="text-sm font-medium leading-6 text-gray-500">
          {props.statName}
        </dt>
        <dd
          className={classNames(
            props.change < 0 ? "text-rose-600" : "text-gray-700",
            "text-xs font-medium"
          )}
        >
          {props.change == 'N/A' ? 'N/A' : (Math.round(props.change * 100) / 100) + '%'}
          {/* {Math.round(props.change * 100) / 100}% */}
        </dd>
        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
         {props.value} {props.changeValue}
        </dd>
      </div>
    </>
  );
}

export default Headline;
