import { React } from "react";
import Comparison from "./Comparison";

function ServicePerformance(props) {

  return (
    <>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
          <div className="flex flex-row items-end justify-between">
            <div className="flex flex-col">
              <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                {props.base}
              </div>
              <div className="text-xs font-medium text-gray-400">
                was {props.compare}
              </div>
            </div>
            <div>
              <Comparison
                base={props.base}
                compare={props.compare}
              />
            </div>
          </div>
        </td>
    </>
  );
}

export default ServicePerformance;
