import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
// import { Mixed } from "../Charts/mixed";
import { CircleFlag } from "react-circle-flags";
import Header from "../Components/header"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const orders = [
  {
    orderNumber: "#12345",
    carrier: "Fedex",
    service: "International Priority",
    despatched: "15th Dec 23",
    transit: "2 days",
    tracking: "Delivered",
    country: "us",
  },
  {
    orderNumber: "#12345",
    carrier: "Fedex",
    service: "International Priority",
    despatched: "15th Dec 23",
    transit: "2 days",
    tracking: "Delivered",
    country: "es",
  },
  {
    orderNumber: "#12345",
    carrier: "Fedex",
    service: "International Priority",
    despatched: "15th Dec 23",
    transit: "2 days",
    tracking: "Delivered",
    country: "mx",
  },
  {
    orderNumber: "#12345",
    carrier: "Fedex",
    service: "International Priority",
    despatched: "15th Dec 23",
    transit: "2 days",
    tracking: "Delivered",
    country: "mx",
  },
];

export default function Details() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [open, setOpen] = useState(false);

  return (
    <>
      <Header />

      <main>
        <div className="mx-16 px-4 sm:px-6 lg:px-8">
          <div className="-mx-4 mt-8 sm:-mx-0">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="w-[15%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Order Number
                  </th>
                  <th
                    scope="col"
                    className="w-[15%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Carrier
                  </th>
                  <th
                    scope="col"
                    className="w-[15%] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Service
                  </th>
                  <th
                    scope="col"
                    className="w-[15%] hidden py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Despatched
                  </th>
                  <th
                    scope="col"
                    className="w-[15%] hidden py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Transit
                  </th>
                  <th
                    scope="col"
                    className="w-[15%] py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Tracking
                  </th>
                  <th
                    scope="col"
                    className="w-[15%] py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Country
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {orders.map((order) => (
                  <tr key={order.orderNumber}>
                    <td
                      className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 underline"
                      onClick={() => setOpen(true)}
                    >
                      {order.orderNumber}
                    </td>
                    <td className="hidden whitespace-nowrap py-4 text-sm text-gray-500 sm:table-cell">
                      {order.carrier}
                    </td>
                    <td className="hidden whitespace-nowrap py-4 text-sm text-gray-500 sm:table-cell">
                      {order.service}
                    </td>
                    <td className="hidden whitespace-nowrap py-4 text-sm text-gray-500 lg:table-cell">
                      {order.despatched}
                    </td>
                    <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                      {order.transit}
                    </td>
                    <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                      {order.tracking}
                    </td>
                    <td className="whitespace-nowrap py-4 text-sm text-gray-500">
                      <CircleFlag countryCode={order.country} width="25" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="bg-indigo-700 px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-white">
                            Order Information
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpen(false)}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-indigo-300">
                            Order information to go here
                          </p>
                        </div>
                      </div>
                      <div className="relative flex-1 px-4 py-6 sm:px-6">
                        {/* Your content */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
