export const getPercentageChange = (base, compare) => {
  if (base === compare) {
    return null;
  } 
  if(compare === 0) {
    return 'N/A'
  }
  else {
    return (100*((base - compare) / compare)).toFixed(2);
  }
};
