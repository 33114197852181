import { useState } from "react";
import { Link } from "react-router-dom";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Bars3Icon } from "@heroicons/react/20/solid";

const navigation = [
  { name: "Explore", href: "#" },
  // { name: "Carriers", href: "#" },
  // { name: "Countries", href: "#" },
  { name: "Overview (WIP)", href: "overview" },
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <header className="z-50 flex h-16 border-b border-gray-900/10">
        <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex flex-1 items-center gap-x-6">
            <button
              type="button"
              className="-m-3 p-3 md:hidden"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-5 w-5 text-gray-900" aria-hidden="true" />
            </button>

            <Link to="/dashboard">
              <img
                className="h-12 w-auto"
                src="/logo.png"
                alt="Carrier Metrics"
              />
            </Link>
          </div>
          <nav className="hidden md:flex md:gap-x-11 md:text-sm md:font-semibold md:leading-6 md:text-gray-700">
            <Link to="/explore">
              Explore
            </Link>

            <Link to="/overview">Overview (WIP)</Link>
          </nav>
          <div className="flex flex-1 items-center justify-end gap-x-8">
            <div>
              <img
                className="h-8 w-8 rounded-full bg-gray-800"
                src="https://joff.me.uk/assets/joff.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
