import { React } from "react";
import { getPercentageChange } from "../../Utils/getHeadlineChange";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Comparison(props) {
  const change = getPercentageChange(props.base, props.compare);

  if (props.compare === 0 || change === 'Infinity') {
    return <div className="text-xs">N/A</div>;
  } else {
    return (
      <>
        <div
          className={classNames(
            change > 0
              ? "bg-green-100 text-green-800"
              : change === null
              ? "bg-gray-100 text-gray-800"
              : "bg-red-100 text-red-800",
            "indicator inline-flex items-baseline rounded-full px-2.5 py-0.5 text-xs font-medium md:mt-2 lg:mt-0"
          )}
        >
          {change > 0 ? (
            <ArrowUpIcon
              className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-green-500"
              aria-hidden="true"
            />
          ) : change === null ? (
            <div>0.00</div>
          ) : (
            <ArrowDownIcon
              className="-ml-1 mr-0.5 h-3 w-3 flex-shrink-0 self-center text-red-500"
              aria-hidden="true"
            />
          )}
          {change}%
        </div>
      </>
    );
  }
}

export default Comparison;
