import { Fragment, useEffect, useState } from "react";
import {
  ClockIcon,
  RocketLaunchIcon,
  TruckIcon,
  SignalSlashIcon,
} from "@heroicons/react/20/solid";
// import { Mixed } from "../Charts/mixed";
import Datepicker from "react-tailwindcss-datepicker";
import Header from "../../Components/header";
import { Link } from "react-router-dom";
import { useGetRequest, sendGetRequest } from "../../Client";
import moment from "moment";
import ReactLoading from "react-loading";
import Highcharts from "highcharts";
import highchartsMap from "highcharts/modules/map";
import HighchartsReact from "highcharts-react-official";

import topology from '../../data/topology.json';

export default function Explore() {

  const [value, setValue] = useState({
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const handleDateChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };


  const options1 = {
    chart: {
      type: "area",
    },
    title: {
      text: "Example graph 1",
      align: "left",
    },
    yAxis: {
      title: {
        useHTML: true,
        text: "Axis description",
      },
    },
    tooltip: {
      shared: true,
      headerFormat:
        '<span style="font-size:12px"><b>{point.key}</b></span><br>',
    },
    plotOptions: {
      series: {
        pointStart: 2012,
      },
      area: {
        stacking: "normal",
        lineColor: "#666666",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: "#666666",
        },
      },
    },
    series: [
      {
        name: "DHL",
        data: [13234, 12729, 11533, 17798, 10398, 12811, 15483, 16196, 16214],
      },
      {
        name: "FedEx",
        data: [6685, 6535, 6389, 6384, 6251, 5725, 5631, 5047, 5039],
      },
      {
        name: "USPS",
        data: [4752, 4820, 4877, 4925, 5006, 4976, 4946, 4911, 4913],
      },
      {
        name: "UPS",
        data: [3164, 3541, 3898, 4115, 3388, 3569, 3887, 4593, 1550],
      },
      {
        name: "Parcelforce",
        data: [2019, 2189, 2150, 2217, 2175, 2257, 2344, 2176, 2186],
      },
    ],
  };

  const options2 = {
    chart: {
      type: "column",
    },
    title: {
      text: "Example graph 2",
      align: "left",
    },
    xAxis: {
      categories: ["Monday", "Tuesday", "Wednesday", "Thursday"],
    },
    yAxis: {
      min: 0,
      title: {
        text: "Count parcels",
      },
      stackLabels: {
        enabled: true,
      },
    },
    legend: {
      align: "left",
      x: 70,
      verticalAlign: "top",
      y: 70,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: "3PL",
        data: [3, 5, 1, 13],
      },
      {
        name: "To Carrier",
        data: [14, 8, 8, 12],
      },
      {
        name: "To Delivery",
        data: [0, 2, 6, 3],
      },
    ],
  };

    return (
      <>
        <Header />

        <main>
          {/* Date range */}
          <form>
            <div className="flex flex-row justify-start items-center ml-20 max-w-7xl px-8 mt-2">
              <div className="w-[33%] px-2">
                <select
                  id="carrier"
                  name="carrier"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">All Carriers</option>
                  <option>USPS</option>
                  <option>DHL</option>
                  <option>FedEX</option>
                </select>
              </div>
              <div className="w-[33%] px-2">
                <select
                  id="service"
                  name="service"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">All Services</option>
                  <option>Express</option>
                  <option>Standard</option>
                  <option>Mail</option>
                </select>
              </div>
              <div className="w-[33%] px-2">
                <select
                  id="country"
                  name="country"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">All Countries</option>
                  <option>United States</option>
                  <option>Canada</option>
                  <option>Mexico</option>
                </select>
              </div>
            </div>
            <div className="flex flex-row justify-start items-center ml-20 max-w-7xl px-8 mt-2">
              <div className="w-[33%] px-2">
                <select
                  id="region"
                  name="region"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">All Regions</option>
                  <option>EU</option>
                  <option>ROW</option>
                </select>
              </div>
              <div className="w-[33%] px-2">
                <Datepicker
                  displayFormat={"DD/MM/YYYY"}
                  value={value}
                  onChange={handleDateChange}
                />
              </div>
              <div className="w-[33%] p-2">
                <button
                  className="p-2 px-10 bg-indigo-600 text-white text-sm font-semibold rounded-md text-center py-2 "
                  type="submit"
                >
                  Search
                </button>
              </div>
            </div>
          </form>

          <div className="px-24 flex flex-row justify-start flex-nowrap mt-10">
            <div className="w-[1/4]">
              <HighchartsReact highcharts={Highcharts} options={options1} />
            </div>
            <div className="w-[1/4]">
              <HighchartsReact highcharts={Highcharts} options={options2} />
            </div>
          </div>

        </main>
      </>
    );
}
