import { Fragment, useEffect, useState } from "react";
import {
  ClockIcon,
  RocketLaunchIcon,
  TruckIcon,
  SignalSlashIcon,
} from "@heroicons/react/20/solid";
// import { Mixed } from "../Charts/mixed";
import Datepicker from "react-tailwindcss-datepicker";
import Header from "../../Components/header";
import { Link } from "react-router-dom";
import { useGetRequest, sendGetRequest } from "../../Client";
import Headline from "./Headline";
import Service from "./Service";
import { getPercentageChange } from "../../Utils/getHeadlineChange";
import moment from "moment";
import ReactLoading from "react-loading";

export default function Dashboard() {
  const [serviceData, setServiceData] = useState({});
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState({
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const [valueCompare, setValueCompare] = useState({
    startDate: moment().subtract(15, "days").format("YYYY-MM-DD"),
    endDate: moment().subtract(8, "days").format("YYYY-MM-DD"),
  });

  const handleDateChange = (newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  const handleDateCompareChange = (newValue) => {
    console.log("newValue:", newValue);
    setValueCompare(newValue);
  };

  // initial data fetch
  const { status, data } = useGetRequest(
    `dashboard?from=${moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD")}&to=${moment().format(
      "YYYY-MM-DD"
    )}&compare_from=${moment()
      .subtract(15, "days")
      .format("YYYY-MM-DD")}&compare_to=${moment()
      .subtract(8, "days")
      .format("YYYY-MM-DD")}`,
    {}
  );

  useEffect(() => {
    setServiceData(data);
  }, [data]);

  // fetch data after form submit
  const fetchServices = async () => {
    setLoading(true);

    const res = await sendGetRequest(
      `dashboard?from=${value.startDate}&to=${value.endDate}&compare_from=${valueCompare.startDate}&compare_to=${valueCompare.endDate}`
    );

    setServiceData(res);
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchServices();
  };

  if (status == "fetched") {
    return (
      <>
        <Header />

        <main>
          {/* Date range */}
          <form onSubmit={handleSubmit}>
            <div className="flex flex-row justify-center items-center ml-20 max-w-7xl px-8 py-4">
              <div className="w-[10%] ml-[2%] text-base font-semibold">
                Date Sent
              </div>
              <div className="w-[30%] ml-[2%]">
                <Datepicker
                  displayFormat={"DD/MM/YYYY"}
                  value={value}
                  onChange={handleDateChange}
                />
              </div>
              <div className="w-[10%] ml-[2%] text-base font-semibold">
                Compare With
              </div>
              <div className="w-[30%] ml-[2%] mr-[2%] border-solid">
                <Datepicker
                  displayFormat={"DD/MM/YYYY"}
                  value={valueCompare}
                  onChange={handleDateCompareChange}
                />
              </div>

              {loading ? (
                <button
                  className="w-[10%] bg-indigo-600 text-white text-sm font-semibold rounded-md text-center py-2 "
                  // type="submit"
                >
                  <div className="flex flex-row justify-center">
                    {/* <div className="ml-[10%]">Update</div> */}
                    <div>
                      <ReactLoading
                        className="ml-[15%]"
                        type={"spin"}
                        color="#ffffff"
                        height={20}
                        width={20}
                      />
                    </div>
                  </div>
                </button>
              ) : (
                <button
                  className="w-[10%] bg-indigo-600 text-white text-sm font-semibold rounded-md text-center py-2 "
                  type="submit"
                >
                  Update now
                </button>
              )}
            </div>
          </form>

          {/* Stats */}
          <div className="bg-gray-50">
            <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
              <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-1 lg:grid-cols-4 lg:px-2 xl:px-0">
                <Headline
                  statName={"Parcels Sent"}
                  value={serviceData?.headlines?.totalParcels}
                  change={getPercentageChange(
                    serviceData?.headlines?.totalParcels,
                    serviceData?.compare?.totalParcels
                  )}
                />
                <Headline
                  statName={"Parcels without tracking"}
                  value={serviceData?.headlines?.withoutTracking}
                  change={getPercentageChange(
                    serviceData?.headlines?.withoutTracking,
                    serviceData?.compare?.withoutTracking
                  )}
                />
                <Headline
                  statName={"Successfully delivered"}
                  value={serviceData?.headlines?.beenDelivered}
                  change={getPercentageChange(
                    serviceData?.headlines?.beenDelivered,
                    serviceData?.compare?.beenDelivered
                  )}
                />
                <Headline
                  statName={"Average delivery time"}
                  value={serviceData?.headlines?.averageDelivery}
                  change={getPercentageChange(
                    serviceData?.headlines?.averageDelivery,
                    serviceData?.compare?.averageDelivery
                  )}
                />
              </dl>
            </div>
          </div>

          {/* Table */}
          <div className="space-y-16 py-4 xl:space-y-20 mb-20">
            <div>
              <div className="mt-2 overflow-hidden border-t border-gray-100">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="w-[20%] py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-0"
                          ></th>
                          <th
                            scope="col"
                            className="w-[20%] px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                          >
                            <div className="flex flex-row items-center">
                              <div>
                                <TruckIcon
                                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-1">Parcels Sent</div>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="w-[20%] px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                          >
                            <div className="flex flex-row items-center">
                              <div>
                                <SignalSlashIcon
                                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-1">
                                Parcels Without Tracking
                              </div>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="w-[20%] px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                          >
                            <div className="flex flex-row items-center">
                              <div>
                                <RocketLaunchIcon
                                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-1">Successfully Delivered</div>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="w-[20%] px-3 py-3.5 text-left text-xs font-semibold text-gray-900"
                          >
                            <div className="flex flex-row items-center">
                              <div>
                                <ClockIcon
                                  className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-gray-500"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-1">Average Delivery Time</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      {serviceData?.services?.length > 0 ? (
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {serviceData?.services?.map((service, key) => (
                            <Service serviceID={key} data={service} />
                          ))}
                        </tbody>
                      ) : <div>NO DATA</div>}
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {/* Recent client list*/}
          </div>
        </main>
      </>
    );
  }
}
