import { React } from "react";

function ServiceCarrier(props) {

  return (
    <>
        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
          <div className="flex items-center">
            <div className="h-11 w-11 flex-shrink-0">
              <img
                className="h-11 w-11 rounded-full"
                src={`/assets/carriers/${props.carrierCode}.png`}
                alt=""
              />
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900">
               {props.carrierName}
              </div>
              <div className="mt-1 text-gray-500">{props.serviceName}</div>
            </div>
          </div>
        </td>
    </>
  );
}

export default ServiceCarrier;
