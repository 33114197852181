import { React } from "react";
import ServicePerformance from "./ServicePerformance";
import ServiceCarrier from "./ServiceCarrier";

function Service(props) {
  return (
    <>
      <tr key={props.data.serviceID}>
        <ServiceCarrier carrierCode={props.data.carrierCode} carrierName={props.data.carrierName} serviceName={props.data.serviceName} />
        <ServicePerformance base={props.data.totalParcels} compare={props.data.compare.totalParcels} />
        <ServicePerformance base={props.data.withoutTracking} compare={props.data.compare.withoutTracking} />
        <ServicePerformance base={props.data.beenDelivered} compare={props.data.compare.beenDelivered} />
        <ServicePerformance base={props.data.averageDelivery} compare={props.data.compare.averageDelivery} />        
      </tr>
    </>
  );
}

export default Service;
