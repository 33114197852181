import axios from "axios";
import { useState, useEffect } from "react";

const endpoint = "https://api.carriermetrics.com";

export const useGetRequest = (path) => {
  const [status, setStatus] = useState("idle");
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setStatus("fetching");
      const res = await axios.get(`${endpoint}/${path}`);
      if (res) {
        setData(res.data);
      }
      setStatus("fetched");
    };

    fetchData();
  }, [path]);

  return { status, data };
};

export const sendPostRequest = async (path, params) => {
  const auth = {
    headers: {
      Authorization: "Bearer 1cb36167c0d86c25fc2c01eeafbf1b8be131dcee",
    },
  };

  const res = await axios.post(`${endpoint}/${path}`, params, auth);

  return res.data;
};

export const sendGetRequest = async (path, params) => {
  const auth = {
    headers: {
      Authorization: "Bearer 1cb36167c0d86c25fc2c01eeafbf1b8be131dcee",
    },
  };

  const res = await axios.get(`${endpoint}/${path}`, params, auth);

  return res.data;
};
